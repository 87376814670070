<template>
    <div v-if="!wspLoader" class="workspace__body">
        <page-header title="Пользователи" icon="user-secret" :navlinks="navlinks" />
        <router-view></router-view>
        <table class="control-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Пользователь</th>
                    <th>Email</th>
                    <th>Sequre</th>
                </tr>
            </thead>
            <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>
                            <router-link :to="'/users/' + user.id">
                            {{ user.id }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="'/users/' + user.id">
                            {{ user.name }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="'/users/' + user.id">
                            {{ user.email }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="'/users/' + user.id">
                            {{ user.level }}
                            </router-link>
                        </td>
                    </tr>
                
            </tbody>
        </table>
        
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Users',
    data(){
        return {
            navlinks: [
                {
                    title: "Добавить пользователя",
                    route: "/users/new",
                    icon: "plus",
                },
            ],
        }
    },
    methods: {
        ...mapActions({
            getUsers: 'GetUsersAction'
        })
    },
    computed: {
        ...mapGetters({
            wspLoader: 'GetWspLoader',
            users: 'GetUsers'
        }),
    },
    created(){
        if(!this.users){
            this.getUsers()
        }
    }
}
</script>
